import { RepoMap } from 'components/RepoMap'
import { Link, PageProps } from 'gatsby'

import React from 'react'
import { useGetTracking } from '../hooks/useGetTracking'

const Tracking: React.FC<PageProps> = ({}) => {
	const { tracking } = useGetTracking()

	return (
		<div>
			<div className='p2'>
				<h2 className='py-2 '>Upcoming Activities</h2>
				<table className='table'>
					<thead>
						<tr>
							<th scope='col'>#</th>
							<th scope='col'>Name</th>
							{/* <th scope='col'>Desc.</th> */}
							<th scope='col'>Type</th>
							<th scope='col'>Course</th>
							<th scope='col'>Open</th>
							<th scope='col'>Last Date</th>
							{/* <th scope='col'>Email</th>
							<th scope='col'>Logs</th> */}
						</tr>
					</thead>
					<tbody>
						{tracking
							?.filter((elem) => elem.__t !== 'ResourceRepository')
							.filter((elem) => new Date(elem.available_till) > new Date())
							.map((elem, index) => {
								return (
									<>
										<tr>
											<th scope='row'>{index + 1}</th>
											<td>{elem.name}</td>
											{/* <td>{elem.description}</td> */}
											<td>{RepoMap[elem.__t]?.toUpperCase()}</td>
											<td>{elem.course.name}</td>
											<td>
												<Link to={`/course/${elem.course.uid}/activities/${RepoMap[elem.__t]}/${elem.uid}`}>Open</Link>
											</td>
											<td>{new Date(elem.available_till).toLocaleString()}</td>
										</tr>
									</>
								)
							})}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default Tracking
